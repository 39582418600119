import styles from "./styles/LinkButton.module.scss";
import React, { ReactNode } from "react";

interface LinkButtonProps {
	platformName: string; // Название площадки
	Icon: ReactNode; // SVG-компонент для иконки
	link: string;
}

const LinkButton: React.FC<LinkButtonProps> = ({
	platformName,
	Icon,
	link,
}) => {
	const handleClick = () => {
		fetch("http://api.amnesiawho.ru/log", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				link,
				platform: platformName,
			}),
		});
		window.location.href = link;
	};

	return (
		<li
			className={`${styles.LinkButton} ${
				link && link ? null : styles.disabled
			}`}
			onClick={() => {
				if (link) {
					handleClick();
				}
			}}
		>
			<div className={styles.avatar}>{Icon}</div>

			<p className={`${styles.platformName} platformName`}>{platformName}</p>
		</li>
	);
};

export default LinkButton;
