import React, { useState } from "react";
import styles from "./styles/Menu.module.scss";
import { ReactComponent as MenuButton } from "./images/menuButton.svg";
import { ReactComponent as MenuButtonOff } from "./images/menuButtonOff.svg";
import LinkButton from "./components/LinkButton/LinkButton";
import { ReactComponent as YoutubeIcon } from "./images/youtube-icon.svg";
import { ReactComponent as YandexMusicIcon } from "./images/yandex-music-icon.svg";
import { ReactComponent as SpotifyIcon } from "./images/spotify-icon.svg";
import { ReactComponent as ITunesIcon } from "./images/itunes-icon.svg";
import { ReactComponent as TTIcon } from "./images/tt-icon.svg";

function Menu() {
	const [isButtonHover, setIsButtonHover] = useState(false); // Состояние наведения на кнопку
	const [isOn, setIsOn] = useState(true); // Состояние открытости меню

	return (
		<div className={`${styles.Menu} ${isOn ? styles.opened : styles.closed}`}>
			<div>
				<button
					className={`${styles.menuButton} ${
						isButtonHover ? styles.hover : null
					}`}
					onClick={() => setIsOn(!isOn)}
					onMouseEnter={() => setIsButtonHover(true)}
					onMouseLeave={() => setIsButtonHover(false)}
				>
					{isOn ? (
						<MenuButton width={"24px"} height={"24px"} />
					) : (
						<MenuButtonOff width={"24px"} height={"24px"} />
					)}
					{isOn ? <p>Мои площадки</p> : null}
				</button>
			</div>
			<ul className={styles.linksDiv}>
				<LinkButton
					platformName="Youtube"
					Icon={<YoutubeIcon />}
					link="https://www.youtube.com/channel/UCd88Y-6UPtAH72mpcEG_Lzw"
				/>
				<LinkButton
					platformName="Яндекс Музыка"
					Icon={<YandexMusicIcon />}
					link="https://music.yandex.ru/artist/21904111"
				/>
				<LinkButton
					platformName="Spotify"
					Icon={<SpotifyIcon />}
					link="https://open.spotify.com/artist/341FEmk5vK4ytbbGLQ7lhC"
				/>
				<LinkButton
					platformName="ITunes"
					Icon={<ITunesIcon />}
					link="https://music.apple.com/ru/artist/heheheyup/1737753751"
				/>
				<LinkButton
					platformName="TikTok"
					Icon={<TTIcon />}
					link="https://vm.tiktok.com/ZMhbth3B4/"
				/>
				{/* <LinkButton platformName="Спотифай" Icon={<SpotifyIcon />} link="https://open.spotify.com/artist/341FEmk5vK4ytbbGLQ7lhC"/>
				<LinkButton platformName="Спотифай" Icon={<SpotifyIcon />} link="https://open.spotify.com/artist/341FEmk5vK4ytbbGLQ7lhC"/>
				<LinkButton platformName="Спотифай" Icon={<SpotifyIcon />} link="https://open.spotify.com/artist/341FEmk5vK4ytbbGLQ7lhC"/>
				<LinkButton platformName="Спотифай" Icon={<SpotifyIcon />} link="https://open.spotify.com/artist/341FEmk5vK4ytbbGLQ7lhC"/>
				<LinkButton platformName="Спотифай" Icon={<SpotifyIcon />} link="https://open.spotify.com/artist/341FEmk5vK4ytbbGLQ7lhC"/> */}
			</ul>
			
		</div>
	);
}

export default Menu;
