import React, { useEffect, useState } from "react";
import styles from "./styles/Home.module.scss";
import VideoCard from "./components/VideoCard/VideoCard";
import {
	fetchLatestYoutubeVideo,
	fetchYoutubeVideos,
} from "../../hooks/useYoutubeAPI/useYoutubeAPI";

interface VideoSnippet {
	title: string;
	description: string;
	publishedAt: string;
	thumbnails: {
		default: { url: string };
		medium: { url: string };
		high: { url: string };
	};
}

interface Video {
	id: {
		videoId: string;
	};
	snippet: VideoSnippet;
}

const VideoComponent: React.FC = () => {
	const [videos, setVideos] = useState<Video[] | null>(null);

	useEffect(() => {
		const loadVideo = async () => {
			const videos = await fetchYoutubeVideos("UCd88Y-6UPtAH72mpcEG_Lzw");
			setVideos(videos);
		};

		loadVideo();
	}, []);

	return (
		<div className={styles.videos}>
			{videos && videos.length > 0 ? (
				videos.map((video, index) => (
					<div key={index} className={styles.video}>
						<img
						  	width={"140px"}
							height={"90px"}
							src={video.snippet.thumbnails.medium.url}
							alt={video.snippet.title}
						/>
						<h2>{video.snippet.title}</h2>
						<p>{video.snippet.description}</p>
					</div>
				))
			) : (
				<p>Загрузка...</p>
			)}
		</div>
	);
};

function Home() {
	return (
		<div className={styles.Home}>
			<iframe
				className={styles.embed}
				style={{ border: "12px" } as React.CSSProperties} // Указываем стиль и типизируем его
				src="https://api.amnesiawho.ru/api/v1/proxy/spotify?artistId=341FEmk5vK4ytbbGLQ7lhC"
				title="Spotify"
				allowFullScreen
				allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
				loading="lazy"
			></iframe>
			<iframe
				style={{ border: "12px" } as React.CSSProperties} // Указываем стиль и типизируем его
				className={styles.embed}
				allow="clipboard-write"
				src="https://music.yandex.ru/iframe/album/32543866"
			/>
			
			<iframe
				className={styles.embed}
				width="400"
				allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
				height="450"
				style={{ maxWidth: "660px", overflow: "hidden", borderRadius: "10px" }}
				sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
				src="https://embed.music.apple.com/ru/album/wertel-single/1780491516"
			></iframe>
			{/* <VideoComponent /> */}
			
		</div>
	);
}

export default Home;
